import { Avatar, Loading } from '@shopify/polaris';
import { User, useCurrentUserQuery } from '../../graphql';
import { Page } from '../../components';
import { SettingsProfileDetails } from './SettingsProfileDetails';

export function SettingsProfile() {
  const { data, loading } = useCurrentUserQuery();

  const user = data?.currentUser;

  const companyTitle = user?.companyTitle;
  const logoUrl = user?.logoUrl;

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return null;
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      narrowWidth
      thumbnail={
        <Avatar
          customer
          name={companyTitle}
          size="large"
          source={logoUrl || ''}
        />
      }
      title={companyTitle}
    >
      <SettingsProfileDetails user={user as User} />
    </Page>
  );
}

const breadcrumbs = [{ url: '/settings' }];
