export const today = new Date();

export const todayState = {
  month: today.getMonth(),
  year: today.getFullYear(),
};

export function readableDate(date: Date): string {
  return date.toISOString().substring(0, 10);
}
