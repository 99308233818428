import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Caption,
  InlineError,
  ProgressBar,
  Stack,
  Thumbnail,
} from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import { UploadSource } from '../../graphql';
import { upload } from '../../utils';
import { UploadedFile } from '../../types';
import './DropZone.css';

interface Props {
  uploadSource: UploadSource;
  file: File;
  id: string;
  onUploadFinished: (file: UploadedFile) => void;
  onRemove: (id: string) => void;
}

export function File({
  id,
  file,
  onUploadFinished,
  onRemove,
  uploadSource,
}: Props) {
  const [progress, setProgress] = useState(0);
  const [failed, setFailed] = useState(false);

  const handleOnRemove = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onRemove(id);
    },
    [id, onRemove],
  );

  useEffect(() => {
    const cancel = upload(
      uploadSource,
      file,
      (err, res) => {
        if (err || !res) {
          console.log(err);
          setFailed(true);
          return;
        }

        const { externalId, url } = res;

        onUploadFinished({
          name: file.name,
          externalId,
          type: file.type,
          localId: id,
          url,
        });
      },
      setProgress,
    );

    return () => cancel();

    /* eslint-disable-next-line */
  }, []);

  return (
    <div className="Item">
      <Stack alignment="center">
        <Thumbnail
          alt={file.name}
          size="small"
          source={
            validImageTypes.indexOf(file.type) > 0
              ? createObjectURL(file)
              : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
          }
        />
        <Stack.Item fill>
          <Caption>{file.name}</Caption>
          {failed ? (
            <InlineError
              fieldID=""
              message="There is an error while uploading"
            />
          ) : (
            <ProgressBar progress={progress * 100} size="small" />
          )}
        </Stack.Item>
        {/* eslint-disable-next-line */}
        <div onClick={handleOnRemove}>
          <Button destructive icon={DeleteMinor} />
        </div>
      </Stack>
    </div>
  );
}

const createObjectURL = (window.URL || window.webkitURL || {}).createObjectURL;
const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
