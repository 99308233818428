import { useCallback, useState } from 'react';
import { CalloutCard, Layout, Toast } from '@shopify/polaris';
import { Redirect } from 'react-router-dom';
import { Page, Spinner } from '../../components';
import { getToken, useCurrentUserQuery } from '../../graphql';
import project from '../../images/project.svg';
import result from '../../images/result.svg';

export function DashboardHome() {
  const token = getToken();

  const [showLastProjectAlert, setShowLastProjectAlert] = useState(false);

  const handleOnDimissShowLastProjectAlert = useCallback(
    () => setShowLastProjectAlert(!showLastProjectAlert),
    [showLastProjectAlert],
  );

  const { data, loading } = useCurrentUserQuery();

  const lastProjectId = data?.currentUser?.lastProjectId;

  const url = lastProjectId ? `/projects/${lastProjectId}` : '';

  const handleViewProject = useCallback(() => {
    if (lastProjectId === null) {
      setShowLastProjectAlert(true);
      return;
    }
  }, [lastProjectId]);

  if (!token) {
    return <Redirect to="/login" />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Page title="Home">
      <Layout>
        <Layout.Section oneHalf>
          <CalloutCard
            illustration={project}
            primaryAction={{
              content: 'Create new project',
              url: '/projects/new',
            }}
            title="New project"
          >
            <p>{'Create a new project for your company.'}</p>
          </CalloutCard>
        </Layout.Section>
        <Layout.Section oneHalf>
          <CalloutCard
            illustration={result}
            primaryAction={{
              onAction: handleViewProject,
              content: 'View project',
              url,
            }}
            title="Last project"
          >
            <p>{'View your last project has created.'}</p>
          </CalloutCard>
        </Layout.Section>
      </Layout>
      {showLastProjectAlert ? (
        <Toast
          content="No project has been created yet!"
          error
          onDismiss={handleOnDimissShowLastProjectAlert}
        />
      ) : null}
    </Page>
  );
}
