import * as Yup from 'yup';

export interface ResetPasswordFormValues {
  password: string;
  passwordConfirmation: string;
}

export const validationSchema = Yup.object({
  password: Yup.string()
    .required('Required field')
    .min(8, 'Password must be at least 8 characters'),
  passwordConfirmation: Yup.string()
    .required('Required field')
    .min(8, 'Password confirmation must be at least 8 characters')
    .oneOf(
      [Yup.ref('password'), null],
      'Password confirmation must match with password',
    ),
});
