import { useCallback, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  DropZoneProps,
  DropZone as PolarisDropZone,
  Stack,
} from '@shopify/polaris';
import { UploadSource } from '../../graphql';
import { id } from '../../utils';
import { FileWithId, UploadedFile } from '../../types';
import { File } from './File';

type Props = Omit<DropZoneProps, 'onDrop'> & {
  name: string;
  uploadSource: UploadSource;
};

export function DropZone({ name, uploadSource, ...props }: Props) {
  const [field] = useField<UploadedFile[]>(name);
  const [files, setFiles] = useState<FileWithId[]>([]);
  const { setFieldValue } = useFormikContext<{ [x: string]: UploadedFile[] }>();

  const handleOnDrop = useCallback((_files, acceptedFiles: File[]) => {
    setFiles((files) => {
      return [...files, ...acceptedFiles.map((file) => ({ file, id: id() }))];
    });
  }, []);

  const handleOnUploadFinished = useCallback(
    (file: UploadedFile) => {
      setFieldValue(name, [...field.value, file]);
    },
    [field.value, name, setFieldValue],
  );

  const handleOnRemove = useCallback(
    (id: string) => {
      setFiles((files) => {
        const newFiles = files.filter(({ id: fid }) => fid !== id);

        setFieldValue(
          name,
          (field.value || []).filter(({ localId }) => localId !== id),
        );

        return newFiles;
      });
    },
    [field.value, name, setFieldValue],
  );

  const inner =
    files && files.length > 0 ? (
      <Stack vertical>
        {files.map(({ file, id }: FileWithId) => (
          <File
            file={file}
            id={id}
            key={id}
            onRemove={handleOnRemove}
            onUploadFinished={handleOnUploadFinished}
            uploadSource={uploadSource}
          />
        ))}
      </Stack>
    ) : (
      <PolarisDropZone.FileUpload />
    );

  return (
    <PolarisDropZone {...props} onDrop={handleOnDrop}>
      {inner}
    </PolarisDropZone>
  );
}
