import {
  Banner,
  Card,
  ContextualSaveBar,
  FormLayout,
  Layout,
  Link,
} from '@shopify/polaris';
import { Form, Formik, FormikHelpers } from 'formik';
import { TextField } from '@satel/formik-polaris';
import { Prompt, useHistory } from 'react-router-dom';
import { UploadSource } from '../../graphql';
import { DropZone, UploadedFiles } from '../../components';
import * as Pdf from '../../content/DATASET_Instructions.pdf';
import * as Dataset from '../../content/Dataset_Sample.csv';
import { ProjectFormValues, validationSchema } from './types';
import { FileLabel } from './FileLabel';
import './ProjectForm.css';

interface Props {
  onSubmit: (
    values: ProjectFormValues,
    helpers: FormikHelpers<ProjectFormValues>,
  ) => Promise<void>;
  values?: ProjectFormValues;
  discardAction?: string;
}

export function ProjectForm({
  onSubmit,
  values = initialValues,
  discardAction,
}: Props) {
  const history = useHistory();

  return (
    <Formik
      initialValues={values}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, dirty, values }) => (
        <Form>
          <ContextualSaveBar
            discardAction={{
              onAction: () => {
                history.push(discardAction || '/projects');
              },
            }}
            message="Unsaved changes"
            saveAction={{
              loading: isSubmitting,
              disabled: !dirty,
              onAction: submitForm,
            }}
          />
          <Prompt message="Are you sure?" when={dirty || isSubmitting} />
          <Layout.AnnotatedSection title={'Project details'}>
            <Card sectioned>
              <FormLayout>
                <TextField label="Title" name="projectTitle" />
                <TextField
                  label="Description"
                  multiline={5}
                  name="projectDescription"
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Media">
            <Card>
              <Card.Section title="Dataset">
                <div className="CardTitlePadding" />
                <UploadedFiles name="dataSetFiles" />
                <DropZone
                  disabled={
                    values.dataSetFiles && values.dataSetFiles.length > 0
                  }
                  label={<FileLabel label={`CSV file only, less than 50MB!`} />}
                  name="dataSetFiles"
                  uploadSource={UploadSource.ProjectUpload}
                />
              </Card.Section>
              <Banner status="info">
                {'Learn more about '}
                <Link external url={Dataset.default}>
                  {`standard dataset `}
                </Link>
                {`and read `}
                <Link external url={Pdf.default}>
                  {`the instruction.`}
                </Link>
              </Banner>
            </Card>
            <Card>
              <Card.Section title="Plot">
                <div className="CardTitlePadding" />
                <UploadedFiles name="plotFiles" />
                <DropZone
                  disabled={values.plotFiles && values.plotFiles.length > 0}
                  label={<FileLabel label={`Image file only!`} />}
                  name="plotFiles"
                  uploadSource={UploadSource.ProjectUpload}
                />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </Form>
      )}
    </Formik>
  );
}

const initialValues: ProjectFormValues = {
  projectTitle: '',
  projectDescription: '',
  dataSetFiles: [],
  plotFiles: [],
};
