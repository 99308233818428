/* eslint-disable react/jsx-no-bind */
import { Button } from '../../common';
import { CustomNavLinkSmall, Span } from './styles';

interface Props {
  setVisibility: (visible: boolean) => void;
}
export function MenuItem({ setVisibility }: Props) {
  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: 'smooth',
    });
    setVisibility(false);
  };
  return (
    <>
      <CustomNavLinkSmall onClick={() => scrollTo('services')}>
        <Span>{'Services'}</Span>
      </CustomNavLinkSmall>
      <CustomNavLinkSmall onClick={() => scrollTo('whatis')}>
        <Span>{"What's Rampco"}</Span>
      </CustomNavLinkSmall>
      <CustomNavLinkSmall onClick={() => scrollTo('ml')}>
        <Span>{'Machine Learning'}</Span>
      </CustomNavLinkSmall>
      <CustomNavLinkSmall
        onClick={() => scrollTo('dashboard')}
        style={{ width: '180px' }}
      >
        <Span>
          <Button>{'RAMPCO'}</Button>
        </Span>
      </CustomNavLinkSmall>
    </>
  );
}
