import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { FormProps, Form as PolarisForm } from '@shopify/polaris';

export function Form(props: Omit<FormProps, 'onSubmit'>) {
  const { handleSubmit, dirty, isSubmitting } = useFormikContext();

  const handleOnSubmit = useCallback(() => {
    if (!dirty || isSubmitting) {
      return;
    }

    handleSubmit();
  }, [dirty, handleSubmit, isSubmitting]);

  return <PolarisForm {...props} onSubmit={handleOnSubmit} />;
}
