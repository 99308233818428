/* eslint-disable react/no-array-index-key */
import {
  Banner,
  BannerStatus,
  DisableableAction,
  LoadableAction,
} from '@shopify/polaris';
import { ApolloError } from '@apollo/client';

interface Props {
  title?: string;
  message?: string;
  error: ApolloError | undefined;
  action?: DisableableAction & LoadableAction;
  status?: BannerStatus;
  onDismiss?: () => void;
}

export function GraphqlErrors({
  title,
  message,
  error,
  action,
  status = 'critical',
  onDismiss,
}: Props) {
  if (!error) {
    return null;
  }

  return (
    <Banner action={action} onDismiss={onDismiss} status={status} title={title}>
      {message ? <p>{message}</p> : null}
      {error.graphQLErrors.map(({ message, extensions }, index) => {
        let error = '';
        const code = extensions?.exception.errcode;

        if (message in errors) {
          error = errors[message as keyof typeof errors];
        } else if (code in errorCodes) {
          error = errorCodes[code as keyof typeof errorCodes];
        }

        return <p key={`${index}${message}`}>{error}</p>;
      })}
    </Banner>
  );
}

const errors = {
  email_does_not_exist: 'Email does not exist.',
  email_not_verified: 'Email is not verified.',
  email_exists: 'Email already exists.',
  bad_password: 'Password must be more than 8 characters.',
  wrong_email_or_password: 'Email or password is wrong.',
  user_full_name_or_company_title_required:
    'Full name or company title is required.',
  user_not_found: 'User not found.',
  invalid_token: 'Invalid reset password link.',
};

const errorCodes = {
  '42501': "You don't have enough access",
  '23514': 'Input is not valid',
};
