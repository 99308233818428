import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getToken } from './token';

const uri = '/graphql';

const httpLink = new HttpLink({
  uri,
});

const authLink = setContext((_, { token }) => {
  token = token || getToken();

  if (token) {
    return {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  }

  return;
});

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});
