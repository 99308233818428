import { useCallback } from 'react';
import { Col, Row } from 'antd';
import { Container, SvgIcon } from '../../common';
import { SocialLink } from './SocialLink';
import {
  AnchorLarge,
  Chat,
  Empty,
  Extra,
  FooterContainer,
  FooterSection,
  Label,
  Language,
  LanguageSwitch,
  LanguageSwitchContainer,
  Large,
  LogoContainer,
  NavLink,
  Para,
  Title,
} from './styles';

export function Footer() {
  const mapHandler = useCallback(
    () => window.open('https://goo.gl/maps/cQaPUJ2Dxkk8wn9S9', '_blank'),
    [],
  );

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Large to="/">{'Tell us everything'}</Large>
              <Para>{`Do you have any question? Feel free to reach out.`}</Para>
              <a href="mailto:contact@rampco.ca">
                <Chat>{`Let's Chat`}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{'Team'}</Title>
              <AnchorLarge
                href="https://rampco.ca/ali-heydari/"
                target="_blank"
              >
                {'Ali Heydari'}
              </AnchorLarge>
              <AnchorLarge
                href="https://rampco.ca/majid-alizadehhamedany/"
                target="_blank"
              >
                {'Majid Alizadeh Hamedany'}
              </AnchorLarge>
              <AnchorLarge
                href="https://rampco.ca/gholamreza-rohisefidmazgi/"
                target="_blank"
              >
                {'Gholamreza Roohi Sefidmazgi'}
              </AnchorLarge>
              <AnchorLarge
                href="https://rampco.ca/mohammadtaghi-ebnesheykhi/"
                target="_blank"
              >
                {'MohammadTaghi Ebnesheykhi'}
              </AnchorLarge>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <AnchorLarge href="https://rampco.ca/contact-us/" left="true">
                {'Support Center'}
              </AnchorLarge>
              <AnchorLarge href="https://rampco.ca/contact-us/" left="true">
                {'Customer Support'}
              </AnchorLarge>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{'Address'}</Language>
              <Para>{`Spark Centre Head Office`}</Para>
              <Para>{`Suite 300, 2 Simcoe Street South`}</Para>
              <Para>{`Oshawa, L1H 8C1, Canada`}</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{'Company'}</Title>
              <AnchorLarge href="https://rampco.ca/about-us/" left="true">
                {'About'}
              </AnchorLarge>
              <AnchorLarge href="https://rampco.ca/blog/" left="true">
                {'Blog'}
              </AnchorLarge>
              <AnchorLarge href="https://rampco.ca/services/" left="true">
                {'Services'}
              </AnchorLarge>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{'Country'}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={mapHandler}>
                  <SvgIcon
                    aria-label="homepage"
                    height="30px"
                    src="canada.svg"
                    width="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border>
          <Row
            align="middle"
            justify="space-between"
            style={{ paddingTop: '3rem' }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon height="61px" src="rampco-logo.png" width="114px" />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://www.instagram.com/rampco.ca/"
                src="instagram.svg"
              />
              <SocialLink
                href="https://linkedin.com/company/rampco-software"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://rampco-software.medium.com/"
                src="medium.svg"
              />
              <NavLink to="/signup">
                <img
                  alt="Register 4 dashboard"
                  src="https://img.buymeacoffee.com/button-api/?text=Register on Rampco&emoji=🤖&slug=adrinlol&button_colour=007046&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=00A366"
                />
              </NavLink>
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
}
