import { useCallback, useState } from 'react';

export function useToggle(
  def = false,
): [boolean, () => void, () => void, () => void] {
  const [o, os] = useState(def);
  const setTrue = useCallback(() => {
    os(true);
  }, []);
  const setFalse = useCallback(() => {
    os(false);
  }, []);
  const toggle = useCallback(() => {
    os((o) => !o);
  }, []);

  return [o, setTrue, setFalse, toggle];
}
