import { Footer, Header } from '../../components';
import { HomeComponent } from './HomeComponent';
import { Styles } from './styles';
import 'antd/dist/antd.css';

export function Home() {
  return (
    <>
      <Styles />
      <Header />
      <HomeComponent />
      <Footer />
    </>
  );
}
