import React from 'react';
import { StyledButton } from './styles';

interface Props {
  color?: string;
  fixedWidth?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

export function Button({ color, fixedWidth, children, onClick }: Props) {
  return (
    <StyledButton color={color} fixedWidth={fixedWidth} onClick={onClick}>
      {children}
    </StyledButton>
  );
}
