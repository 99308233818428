import { useCallback } from 'react';
import { Card, DescriptionList } from '@shopify/polaris';
import { User, useUpdateUserMutation } from '../../graphql';
import { useToggle } from '../../hooks';
import { SettingsProfileEditModal } from './SettingsProfileEditModal';
import { SettingProfileFormValues } from './types';

interface SettingsProfileDetailsProps {
  user: User;
}

export function SettingsProfileDetails({
  user: {
    id,
    fullName,
    email,
    companyTitle,
    companyDescription,
    companyPhoneNumber,
    companyWebsiteUrl,
    companyAddress,
    logoUrl,
  },
}: SettingsProfileDetailsProps) {
  const [
    isSettingProfileModalOpen,
    handleOpenSettingProfileModal,
    handleCloseSettingProfileModal,
  ] = useToggle();

  const values: SettingProfileFormValues = {
    userFullName: fullName,
    companyTitle,
    companyDescription: companyDescription || '',
    companyPhoneNumber: companyPhoneNumber || '',
    companyWebsiteUrl: companyWebsiteUrl || '',
    companyAddress: companyAddress || '',
  };

  const [updateUser] = useUpdateUserMutation();

  const handleOnSubmit = useCallback(
    async ({
      companyDescription,
      companyPhoneNumber,
      companyWebsiteUrl,
      companyAddress,
      logo,
      ...patch
    }: SettingProfileFormValues) => {
      const { data } = await updateUser({
        variables: {
          input: {
            id,
            patch: {
              companyDescription: companyDescription || null,
              companyPhoneNumber: companyPhoneNumber || null,
              companyWebsiteUrl: companyWebsiteUrl || null,
              companyAddress: companyAddress || null,
              companyLogoExternalId: logo?.externalId,
              ...patch,
            },
          },
        },
      });

      if (data?.updateUser?.user?.id) {
        handleCloseSettingProfileModal();
      }
    },
    [handleCloseSettingProfileModal, id, updateUser],
  );

  return (
    <Card
      actions={[
        {
          content: 'Edit',
          onAction: handleOpenSettingProfileModal,
        },
      ]}
      sectioned
      title="Profile"
    >
      <DescriptionList
        items={[
          {
            term: 'Full name',
            description: fullName,
          },
          {
            term: 'Email',
            description: email,
          },
          {
            term: 'Company title',
            description: companyTitle,
          },
          {
            term: 'Company description',
            description: companyDescription || '-',
          },
          {
            term: 'Phone number',
            description: companyPhoneNumber || '-',
          },
          {
            term: 'Website',
            description: companyWebsiteUrl || '-',
          },
          {
            term: 'Address',
            description: companyAddress || '-',
          },
        ]}
      />
      <SettingsProfileEditModal
        logoUrl={logoUrl || ''}
        onClose={handleCloseSettingProfileModal}
        onSubmit={handleOnSubmit}
        open={isSettingProfileModalOpen}
        values={values}
      />
    </Card>
  );
}
