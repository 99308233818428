import * as Yup from 'yup';

export interface LoginFormValues {
  email: string;
  password: string;
}

export const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required field'),
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Required field'),
});
