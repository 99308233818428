import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /**
   * A JSON Web Token defined by [RFC 7519](https://tools.ietf.org/html/rfc7519)
   * which securely represents claims between two parties.
   */
  JwtToken: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

/** All input for the `createForgotPasswordToken` mutation. */
export type CreateForgotPasswordTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `createForgotPasswordToken` mutation. */
export type CreateForgotPasswordTokenPayload = {
  __typename?: 'CreateForgotPasswordTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  boolean?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CreatePresignedUploadLinkInput = {
  mimeType: Scalars['String'];
  uploadSource: UploadSource;
};

export type CreatePresignedUploadLinkPayload = {
  __typename?: 'CreatePresignedUploadLinkPayload';
  postURL: Scalars['String'];
  getURL: Scalars['String'];
  formData: Scalars['JSON'];
  fileKey: Scalars['UUID'];
};

export type CreateProjectInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  files: Array<ProjectUploadFile>;
};

export type CreateProjectPayload = {
  __typename?: 'CreateProjectPayload';
  query?: Maybe<Query>;
  project?: Maybe<Project>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Datetime']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Datetime']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Int']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Int']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};

/** All input for the `loginUser` mutation. */
export type LoginUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

/** The output of our `loginUser` mutation. */
export type LoginUserPayload = {
  __typename?: 'LoginUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  createForgotPasswordToken?: Maybe<CreateForgotPasswordTokenPayload>;
  loginUser?: Maybe<LoginUserPayload>;
  registerUser?: Maybe<RegisterUserPayload>;
  resetPasswordByToken?: Maybe<ResetPasswordByTokenPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  verifyEmail?: Maybe<VerifyEmailPayload>;
  createPresignedUploadLink?: Maybe<CreatePresignedUploadLinkPayload>;
  createProject?: Maybe<CreateProjectPayload>;
  updateProject?: Maybe<UpdateProjectPayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateForgotPasswordTokenArgs = {
  input: CreateForgotPasswordTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginUserArgs = {
  input: LoginUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetPasswordByTokenArgs = {
  input: ResetPasswordByTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePresignedUploadLinkArgs = {
  input: CreatePresignedUploadLinkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

/** A project that created by user. */
export type Project = Node & {
  __typename?: 'Project';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary unique identifier for the project. */
  id: Scalars['Int'];
  /** The title of the project (2 to 200 char). */
  title: Scalars['String'];
  /** The description of the project (4 to 400 char). */
  description: Scalars['String'];
  /** The result of the project that returns by ml api */
  result?: Maybe<Scalars['JSON']>;
  /** The error log of the project that returns by ml api */
  errorLog?: Maybe<Scalars['String']>;
  /** The time this project was created. */
  createdAt: Scalars['Datetime'];
  /** The time this project was updated. */
  updatedAt: Scalars['Datetime'];
  /** The time this project has been deleted. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `ProjectFile`. */
  files: ProjectFilesConnection;
};

/** A project that created by user. */
export type ProjectFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProjectFilesOrderBy>>;
  condition?: Maybe<ProjectFileCondition>;
  filter?: Maybe<ProjectFileFilter>;
};

/** A condition to be used against `Project` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProjectCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
};

/** A file that uploaded for the project. */
export type ProjectFile = Node & {
  __typename?: 'ProjectFile';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The name of file (2 to 200 char). */
  name: Scalars['String'];
  /** The file external_id. */
  externalId: Scalars['UUID'];
  /** The id of the project associated with this file. */
  projectId: Scalars['Int'];
  /** The type of file. */
  type: ProjectType;
  /** The time this project file was created. */
  createdAt: Scalars['Datetime'];
  /** The time this project file has been deleted. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Project` that is related to this `ProjectFile`. */
  project?: Maybe<Project>;
  url?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ProjectFile` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ProjectFileCondition = {
  /** Checks for equality with the object’s `externalId` field. */
  externalId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `ProjectFile` object types. All fields are combined with a logical ‘and.’ */
export type ProjectFileFilter = {
  /** Filter by the object’s `externalId` field. */
  externalId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<IntFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `project` relation. */
  project?: Maybe<ProjectFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ProjectFileFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ProjectFileFilter>>;
  /** Negates the expression. */
  not?: Maybe<ProjectFileFilter>;
};

/** A connection to a list of `ProjectFile` values. */
export type ProjectFilesConnection = {
  __typename?: 'ProjectFilesConnection';
  /** A list of `ProjectFile` objects. */
  nodes: Array<ProjectFile>;
  /** A list of edges which contains the `ProjectFile` and cursor to aid in pagination. */
  edges: Array<ProjectFilesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectFile` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectFile` edge in the connection. */
export type ProjectFilesEdge = {
  __typename?: 'ProjectFilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProjectFile` at the end of the edge. */
  node: ProjectFile;
};

/** Methods to use when ordering `ProjectFile`. */
export enum ProjectFilesOrderBy {
  Natural = 'NATURAL',
  ExternalIdAsc = 'EXTERNAL_ID_ASC',
  ExternalIdDesc = 'EXTERNAL_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** A filter to be used against `Project` object types. All fields are combined with a logical ‘and.’ */
export type ProjectFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `files` relation. */
  files?: Maybe<ProjectToManyProjectFileFilter>;
  /** Some related `files` exist. */
  filesExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ProjectFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ProjectFilter>>;
  /** Negates the expression. */
  not?: Maybe<ProjectFilter>;
};

/** A filter to be used against many `ProjectFile` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectFileFilter = {
  /** Every related `ProjectFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ProjectFileFilter>;
  /** Some related `ProjectFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ProjectFileFilter>;
  /** No related `ProjectFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ProjectFileFilter>;
};

export enum ProjectType {
  Dataset = 'DATASET',
  Plot = 'PLOT',
}

export type ProjectUploadFile = {
  name: Scalars['String'];
  externalId: Scalars['String'];
  type: ProjectType;
};

/** A connection to a list of `Project` values. */
export type ProjectsConnection = {
  __typename?: 'ProjectsConnection';
  /** A list of `Project` objects. */
  nodes: Array<Project>;
  /** A list of edges which contains the `Project` and cursor to aid in pagination. */
  edges: Array<ProjectsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Project` edge in the connection. */
export type ProjectsEdge = {
  __typename?: 'ProjectsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Project` at the end of the edge. */
  node: Project;
};

/** Methods to use when ordering `Project`. */
export enum ProjectsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** Reads and enables pagination through a set of `ProjectFile`. */
  projectFiles?: Maybe<ProjectFilesConnection>;
  /** Reads and enables pagination through a set of `Project`. */
  projects?: Maybe<ProjectsConnection>;
  projectFile?: Maybe<ProjectFile>;
  project?: Maybe<Project>;
  currentUser?: Maybe<User>;
  currentUserId?: Maybe<Scalars['Int']>;
  /** Reads a single `ProjectFile` using its globally unique `ID`. */
  projectFileByNodeId?: Maybe<ProjectFile>;
  /** Reads a single `Project` using its globally unique `ID`. */
  projectByNodeId?: Maybe<Project>;
  project_?: Maybe<Project>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProjectFilesOrderBy>>;
  condition?: Maybe<ProjectFileCondition>;
  filter?: Maybe<ProjectFileFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProjectsOrderBy>>;
  condition?: Maybe<ProjectCondition>;
  filter?: Maybe<ProjectFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectFileArgs = {
  externalId: Scalars['UUID'];
  projectId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectFileByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProject_Args = {
  id: Scalars['Int'];
};

/** All input for the `registerUser` mutation. */
export type RegisterUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userFullName?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userPassword?: Maybe<Scalars['String']>;
  companyTitle?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyWebsiteUrl?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyPhoneNumber?: Maybe<Scalars['String']>;
  companyLogoExternalId?: Maybe<Scalars['UUID']>;
};

/** The output of our `registerUser` mutation. */
export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `resetPasswordByToken` mutation. */
export type ResetPasswordByTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['UUID'];
  password: Scalars['String'];
};

/** The output of our `resetPasswordByToken` mutation. */
export type ResetPasswordByTokenPayload = {
  __typename?: 'ResetPasswordByTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  boolean?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['UUID']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['UUID']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

export type UpdateProjectInput = {
  id: Scalars['Int'];
  patch: UpdateProjectPatch;
};

export type UpdateProjectPatch = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<ProjectUploadFile>>;
};

export type UpdateProjectPayload = {
  __typename?: 'UpdateProjectPayload';
  query?: Maybe<Query>;
  project?: Maybe<Project>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  patch: UpdateUserPatchRecordInput;
};

/** An input for mutations affecting `UpdateUserPatchRecord` */
export type UpdateUserPatchRecordInput = {
  userFullName?: Maybe<Scalars['String']>;
  companyTitle?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyWebsiteUrl?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyPhoneNumber?: Maybe<Scalars['String']>;
  companyLogoExternalId?: Maybe<Scalars['UUID']>;
};

/** The output of our `updateUser` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export enum UploadSource {
  ProjectUpload = 'PROJECT_UPLOAD',
  ProfileUpload = 'PROFILE_UPLOAD',
}

/** A user who can log in to the application. */
export type User = Node & {
  __typename?: 'User';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary unique identifier for the user. */
  id: Scalars['Int'];
  /** The user’s full name (2 to 200 char). */
  fullName: Scalars['String'];
  /** The company’s title (2 to 200 char). */
  companyTitle: Scalars['String'];
  /** The company’s description (4 to 400 char). */
  companyDescription?: Maybe<Scalars['String']>;
  /** The company’s website url (5 to 100 char, start with ^https?:\/\/). */
  companyWebsiteUrl?: Maybe<Scalars['String']>;
  /** The company’s address (5 to 200 char). */
  companyAddress?: Maybe<Scalars['String']>;
  /** The company’s phone number (10 to 15 char). */
  companyPhoneNumber?: Maybe<Scalars['String']>;
  /** The company’s logo external id for getting logo url. */
  companyLogoExternalId?: Maybe<Scalars['UUID']>;
  /** The time this user was created. */
  createdAt: Scalars['Datetime'];
  /** The time this user was updated. */
  updatedAt: Scalars['Datetime'];
  email?: Maybe<Scalars['String']>;
  lastProjectId?: Maybe<Scalars['Int']>;
  logoUrl?: Maybe<Scalars['String']>;
};

/** All input for the `verifyEmail` mutation. */
export type VerifyEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['UUID'];
};

/** The output of our `verifyEmail` mutation. */
export type VerifyEmailPayload = {
  __typename?: 'VerifyEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  boolean?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginUserMutation = { __typename?: 'Mutation' } & {
  loginUser?: Maybe<
    { __typename?: 'LoginUserPayload' } & Pick<LoginUserPayload, 'jwtToken'>
  >;
};

export type VerifyEmailMutationVariables = Exact<{
  input: VerifyEmailInput;
}>;

export type VerifyEmailMutation = { __typename?: 'Mutation' } & {
  verifyEmail?: Maybe<
    { __typename?: 'VerifyEmailPayload' } & Pick<VerifyEmailPayload, 'boolean'>
  >;
};

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput;
}>;

export type RegisterUserMutation = { __typename?: 'Mutation' } & {
  registerUser?: Maybe<
    { __typename?: 'RegisterUserPayload' } & {
      user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'nodeId'>>;
    }
  >;
};

export type CreateForgotPasswordTokenMutationVariables = Exact<{
  input: CreateForgotPasswordTokenInput;
}>;

export type CreateForgotPasswordTokenMutation = { __typename?: 'Mutation' } & {
  createForgotPasswordToken?: Maybe<
    { __typename?: 'CreateForgotPasswordTokenPayload' } & Pick<
      CreateForgotPasswordTokenPayload,
      'boolean'
    >
  >;
};

export type ResetPasswordByTokenMutationVariables = Exact<{
  input: ResetPasswordByTokenInput;
}>;

export type ResetPasswordByTokenMutation = { __typename?: 'Mutation' } & {
  resetPasswordByToken?: Maybe<
    { __typename?: 'ResetPasswordByTokenPayload' } & Pick<
      ResetPasswordByTokenPayload,
      'boolean'
    >
  >;
};

export type CreatePresignedUploadLinkMutationVariables = Exact<{
  input: CreatePresignedUploadLinkInput;
}>;

export type CreatePresignedUploadLinkMutation = { __typename?: 'Mutation' } & {
  createPresignedUploadLink?: Maybe<
    { __typename?: 'CreatePresignedUploadLinkPayload' } & Pick<
      CreatePresignedUploadLinkPayload,
      'postURL' | 'getURL' | 'fileKey' | 'formData'
    >
  >;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser?: Maybe<
    { __typename?: 'UpdateUserPayload' } & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'fullName'
          | 'email'
          | 'companyTitle'
          | 'companyDescription'
          | 'companyAddress'
          | 'companyPhoneNumber'
          | 'companyWebsiteUrl'
          | 'logoUrl'
        >
      >;
    }
  >;
};

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;

export type CreateProjectMutation = { __typename?: 'Mutation' } & {
  createProject?: Maybe<
    { __typename?: 'CreateProjectPayload' } & {
      project?: Maybe<
        { __typename?: 'Project' } & Pick<Project, 'id'> & {
            files: { __typename?: 'ProjectFilesConnection' } & {
              nodes: Array<
                { __typename?: 'ProjectFile' } & Pick<
                  ProjectFile,
                  'name' | 'externalId' | 'type'
                >
              >;
            };
          }
      >;
    }
  >;
};

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;

export type UpdateProjectMutation = { __typename?: 'Mutation' } & {
  updateProject?: Maybe<
    { __typename?: 'UpdateProjectPayload' } & {
      project?: Maybe<
        { __typename?: 'Project' } & Pick<Project, 'id' | 'nodeId'>
      >;
    }
  >;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      | 'id'
      | 'nodeId'
      | 'fullName'
      | 'email'
      | 'createdAt'
      | 'updatedAt'
      | 'companyTitle'
      | 'companyDescription'
      | 'companyAddress'
      | 'companyPhoneNumber'
      | 'companyWebsiteUrl'
      | 'logoUrl'
      | 'lastProjectId'
    >
  >;
};

export type ProjectsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProjectsOrderBy> | ProjectsOrderBy>;
  filter?: Maybe<ProjectFilter>;
}>;

export type ProjectsQuery = { __typename?: 'Query' } & {
  projects?: Maybe<
    { __typename?: 'ProjectsConnection' } & Pick<
      ProjectsConnection,
      'totalCount'
    > & {
        nodes: Array<
          { __typename?: 'Project' } & Pick<
            Project,
            | 'id'
            | 'nodeId'
            | 'title'
            | 'description'
            | 'result'
            | 'errorLog'
            | 'createdAt'
          >
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'hasPreviousPage'
        >;
      }
  >;
};

export type ProjectQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ProjectQuery = { __typename?: 'Query' } & {
  project_?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      'id' | 'title' | 'description' | 'result' | 'errorLog'
    >
  >;
};

export type ProjectFilesQueryVariables = Exact<{
  filter?: Maybe<ProjectFileFilter>;
}>;

export type ProjectFilesQuery = { __typename?: 'Query' } & {
  projectFiles?: Maybe<
    { __typename?: 'ProjectFilesConnection' } & {
      nodes: Array<
        { __typename?: 'ProjectFile' } & Pick<
          ProjectFile,
          'nodeId' | 'type' | 'externalId' | 'name' | 'url'
        >
      >;
    }
  >;
};

export const LoginUserDocument = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      jwtToken
    }
  }
`;
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginUserMutation,
    LoginUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options,
  );
}
export type LoginUserMutationHookResult = ReturnType<
  typeof useLoginUserMutation
>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const VerifyEmailDocument = gql`
  mutation VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      boolean
    }
  }
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    VerifyEmailDocument,
    options,
  );
}
export type VerifyEmailMutationHookResult = ReturnType<
  typeof useVerifyEmailMutation
>;
export type VerifyEmailMutationResult =
  Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const RegisterUserDocument = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      user {
        id
        nodeId
      }
    }
  }
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, options);
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>;
export type RegisterUserMutationResult =
  Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const CreateForgotPasswordTokenDocument = gql`
  mutation CreateForgotPasswordToken($input: CreateForgotPasswordTokenInput!) {
    createForgotPasswordToken(input: $input) {
      boolean
    }
  }
`;
export type CreateForgotPasswordTokenMutationFn = Apollo.MutationFunction<
  CreateForgotPasswordTokenMutation,
  CreateForgotPasswordTokenMutationVariables
>;

/**
 * __useCreateForgotPasswordTokenMutation__
 *
 * To run a mutation, you first call `useCreateForgotPasswordTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateForgotPasswordTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createForgotPasswordTokenMutation, { data, loading, error }] = useCreateForgotPasswordTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateForgotPasswordTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateForgotPasswordTokenMutation,
    CreateForgotPasswordTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateForgotPasswordTokenMutation,
    CreateForgotPasswordTokenMutationVariables
  >(CreateForgotPasswordTokenDocument, options);
}
export type CreateForgotPasswordTokenMutationHookResult = ReturnType<
  typeof useCreateForgotPasswordTokenMutation
>;
export type CreateForgotPasswordTokenMutationResult =
  Apollo.MutationResult<CreateForgotPasswordTokenMutation>;
export type CreateForgotPasswordTokenMutationOptions =
  Apollo.BaseMutationOptions<
    CreateForgotPasswordTokenMutation,
    CreateForgotPasswordTokenMutationVariables
  >;
export const ResetPasswordByTokenDocument = gql`
  mutation ResetPasswordByToken($input: ResetPasswordByTokenInput!) {
    resetPasswordByToken(input: $input) {
      boolean
    }
  }
`;
export type ResetPasswordByTokenMutationFn = Apollo.MutationFunction<
  ResetPasswordByTokenMutation,
  ResetPasswordByTokenMutationVariables
>;

/**
 * __useResetPasswordByTokenMutation__
 *
 * To run a mutation, you first call `useResetPasswordByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordByTokenMutation, { data, loading, error }] = useResetPasswordByTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordByTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordByTokenMutation,
    ResetPasswordByTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordByTokenMutation,
    ResetPasswordByTokenMutationVariables
  >(ResetPasswordByTokenDocument, options);
}
export type ResetPasswordByTokenMutationHookResult = ReturnType<
  typeof useResetPasswordByTokenMutation
>;
export type ResetPasswordByTokenMutationResult =
  Apollo.MutationResult<ResetPasswordByTokenMutation>;
export type ResetPasswordByTokenMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordByTokenMutation,
  ResetPasswordByTokenMutationVariables
>;
export const CreatePresignedUploadLinkDocument = gql`
  mutation CreatePresignedUploadLink($input: CreatePresignedUploadLinkInput!) {
    createPresignedUploadLink(input: $input) {
      postURL
      getURL
      fileKey
      formData
    }
  }
`;
export type CreatePresignedUploadLinkMutationFn = Apollo.MutationFunction<
  CreatePresignedUploadLinkMutation,
  CreatePresignedUploadLinkMutationVariables
>;

/**
 * __useCreatePresignedUploadLinkMutation__
 *
 * To run a mutation, you first call `useCreatePresignedUploadLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignedUploadLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignedUploadLinkMutation, { data, loading, error }] = useCreatePresignedUploadLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresignedUploadLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePresignedUploadLinkMutation,
    CreatePresignedUploadLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePresignedUploadLinkMutation,
    CreatePresignedUploadLinkMutationVariables
  >(CreatePresignedUploadLinkDocument, options);
}
export type CreatePresignedUploadLinkMutationHookResult = ReturnType<
  typeof useCreatePresignedUploadLinkMutation
>;
export type CreatePresignedUploadLinkMutationResult =
  Apollo.MutationResult<CreatePresignedUploadLinkMutation>;
export type CreatePresignedUploadLinkMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePresignedUploadLinkMutation,
    CreatePresignedUploadLinkMutationVariables
  >;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        fullName
        email
        companyTitle
        companyDescription
        companyAddress
        companyPhoneNumber
        companyWebsiteUrl
        logoUrl
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const CreateProjectDocument = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        id
        files {
          nodes {
            name
            externalId
            type
          }
        }
      }
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, options);
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>;
export type CreateProjectMutationResult =
  Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        id
        nodeId
      }
    }
  }
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UpdateProjectDocument, options);
}
export type UpdateProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectMutation
>;
export type UpdateProjectMutationResult =
  Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      id
      nodeId
      fullName
      email
      createdAt
      updatedAt
      companyTitle
      companyDescription
      companyAddress
      companyPhoneNumber
      companyWebsiteUrl
      logoUrl
      lastProjectId
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const ProjectsDocument = gql`
  query Projects(
    $first: Int
    $offset: Int
    $orderBy: [ProjectsOrderBy!]
    $filter: ProjectFilter
  ) {
    projects(
      first: $first
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      nodes {
        id
        nodeId
        title
        description
        result
        errorLog
        createdAt
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    options,
  );
}
export function useProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectsQuery,
    ProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    options,
  );
}
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<
  typeof useProjectsLazyQuery
>;
export type ProjectsQueryResult = Apollo.QueryResult<
  ProjectsQuery,
  ProjectsQueryVariables
>;
export const ProjectDocument = gql`
  query Project($id: Int!) {
    project_(id: $id) {
      id
      title
      description
      result
      errorLog
    }
  }
`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectDocument,
    options,
  );
}
export function useProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectQuery,
    ProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectDocument,
    options,
  );
}
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<
  ProjectQuery,
  ProjectQueryVariables
>;
export const ProjectFilesDocument = gql`
  query ProjectFiles($filter: ProjectFileFilter) {
    projectFiles(filter: $filter) {
      nodes {
        nodeId
        type
        externalId
        name
        url
      }
    }
  }
`;

/**
 * __useProjectFilesQuery__
 *
 * To run a query within a React component, call `useProjectFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectFilesQuery,
    ProjectFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectFilesQuery, ProjectFilesQueryVariables>(
    ProjectFilesDocument,
    options,
  );
}
export function useProjectFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectFilesQuery,
    ProjectFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectFilesQuery, ProjectFilesQueryVariables>(
    ProjectFilesDocument,
    options,
  );
}
export type ProjectFilesQueryHookResult = ReturnType<
  typeof useProjectFilesQuery
>;
export type ProjectFilesLazyQueryHookResult = ReturnType<
  typeof useProjectFilesLazyQuery
>;
export type ProjectFilesQueryResult = Apollo.QueryResult<
  ProjectFilesQuery,
  ProjectFilesQueryVariables
>;
