import { AppProvider } from '@shopify/polaris';
import { ThemeConfig } from '@shopify/polaris/dist/types/latest/src/utilities/theme';
import en from '@shopify/polaris/locales/en.json';
import { ApolloProvider } from '@apollo/client';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { client } from './graphql';
import {
  ForgotPassword,
  Home,
  Login,
  ResetPassword,
  Signup,
  VerifyEmail,
} from './screens';
import { Dashboard } from './routes';
import { Link } from './components';
import '@shopify/polaris/dist/styles.css';

const theme: ThemeConfig = {
  colors: {
    primary: '#007046',
    interactive: '#00A366',
  },
  logo: {
    width: 100,
    topBarSource:
      'https://user-images.githubusercontent.com/2977960/124597055-3a456400-de78-11eb-9881-aa944ef998d4.png',
    url: '',
    accessibilityLabel: 'Rampco',
  },
};

export function App() {
  return (
    <AppProvider i18n={en} linkComponent={Link} theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
              <Route component={Home} exact path="/home" />
              <Route component={Signup} exact path="/signup" />
              <Route component={Login} exact path="/login" />
              <Route
                component={VerifyEmail}
                exact
                path="/verify-email/:token"
              />
              <Route component={ForgotPassword} exact path="/forgot-password" />
              <Route
                component={ResetPassword}
                exact
                path="/reset-password/:token"
              />
              <Route component={Dashboard} path="/" />
            </Switch>
          </QueryParamProvider>
        </Router>
      </ApolloProvider>
    </AppProvider>
  );
}
