import * as Yup from 'yup';
import { UploadedFile } from '../../types';

export interface SettingProfileFormValues {
  userFullName: string;
  companyTitle: string;
  companyDescription: string;
  companyPhoneNumber: string;
  companyWebsiteUrl: string;
  companyAddress: string;
  logo?: UploadedFile;
}

export const validationSchema = Yup.object({
  userFullName: Yup.string()
    .required('Required field')
    .min(2, 'Full name must be at least 2 characters')
    .max(200, 'Full name must be less than 200 characters'),
  companyTitle: Yup.string()
    .required('Required field')
    .min(2, 'Title must be at least 2 characters')
    .max(200, 'Title must be less than 200 characters'),
  companyDescription: Yup.string()
    .min(4, 'Description must be at least 4 characters')
    .max(400, 'Description must be less than 400 characters'),
  companyPhoneNumber: Yup.string()
    .min(10, 'Phone number must be at least 10 characters')
    .max(15, 'Phone number must be less than 15 characters'),
  companyWebsiteUrl: Yup.string()
    .url()
    .min(5, 'Website must be at least 5 characters')
    .max(100, 'Website must be less than 100 characters'),
  companyAddress: Yup.string()
    .min(5, 'Address must be at least 5 characters')
    .max(200, 'Address must be less than 200 characters'),
});
