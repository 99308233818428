import { useCallback, useEffect, useState } from 'react';
import { SvgIcon } from '../SvgIcon';
import { getScroll } from '../../utils';
import { ScrollUpContainer } from './styles';

export function ScrollToTop() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(
    (event: any) => {
      const offsetFromTop = getScroll(event.target, true);

      if (!showScroll && offsetFromTop > 350) {
        setShowScroll(true);
      } else if (offsetFromTop <= 350) {
        setShowScroll(false);
      }
    },
    [showScroll],
  );

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  const scrollUp = useCallback(() => {
    const element = document.getElementById('intro') as HTMLDivElement;
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }, []);

  return (
    <ScrollUpContainer onClick={scrollUp} show={showScroll}>
      <SvgIcon height="20px" src="scroll-top.svg" width="20px" />
    </ScrollUpContainer>
  );
}
