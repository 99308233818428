import { useCallback } from 'react';
import { TopBar as PolarisTopBar } from '@shopify/polaris';
import { useCurrentUserQuery } from '../../graphql';
import { useToggle } from '../../hooks';

interface Props {
  toggleNav: () => void;
}

export function TopBar({ toggleNav }: Props) {
  const [userMenuOpen, , , handleToggleUserMenuOpen] = useToggle();

  const { data } = useCurrentUserQuery();
  const user = data?.currentUser;

  const companyTitle = user?.companyTitle;
  const logoUrl = user?.logoUrl;

  const handleOnLogout = useCallback(() => {
    localStorage.clear();
    window.location.href = '/';
  }, []);

  return (
    <PolarisTopBar
      onNavigationToggle={toggleNav}
      showNavigationToggle
      userMenu={
        <PolarisTopBar.UserMenu
          actions={[
            {
              items: [
                {
                  content: 'Logout',
                  onAction: handleOnLogout,
                },
              ],
            },
          ]}
          avatar={logoUrl || ''}
          initials={''}
          name={companyTitle || ''}
          onToggle={handleToggleUserMenuOpen}
          open={userMenuOpen}
        />
      }
    />
  );
}
