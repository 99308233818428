import { Route, Switch } from 'react-router-dom';
import {
  EditProject,
  NewProject,
  ProjectDetails,
  ProjectsHome,
} from '../screens';

export function Projects() {
  return (
    <Switch>
      <Route component={ProjectsHome} exact path="/projects" />
      <Route component={NewProject} exact path="/projects/new" />
      <Route component={ProjectDetails} exact path="/projects/:id" />
      <Route component={EditProject} exact path="/projects/edit/:id" />
    </Switch>
  );
}
