import { useCallback, useState } from 'react';
import { Col, Drawer, Row } from 'antd';
import { Label } from 'recharts';
import { Container, SvgIcon } from '../../common';
import { MenuItem } from './MenuItem';
import {
  Burger,
  HeaderSection,
  LogoContainer,
  Menu,
  NotHidden,
  Outline,
} from './styles';

export function Header() {
  const [visible, setVisibility] = useState(false);

  const showDrawer = useCallback(() => {
    setVisibility(!visible);
  }, [visible]);

  const onClose = useCallback(() => {
    setVisibility(!visible);
  }, [visible]);
  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer aria-label="homepage" to="/">
            <SvgIcon height="61px" src="rampco-logo.png" width="114px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem setVisibility={setVisibility} />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} onClose={onClose} visible={visible}>
          <Col style={{ marginBottom: '2.5rem' }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>{`Menu`}</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem setVisibility={setVisibility} />
        </Drawer>
      </Container>
    </HeaderSection>
  );
}
