import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      jwtToken
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      boolean
    }
  }
`;

export const REGISTER_USER = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      user {
        id
        nodeId
      }
    }
  }
`;

export const CREATE_FORGOT_PASSWORD_TOKEN = gql`
  mutation CreateForgotPasswordToken($input: CreateForgotPasswordTokenInput!) {
    createForgotPasswordToken(input: $input) {
      boolean
    }
  }
`;

export const RESET_PASSWORD_BY_TOKEN = gql`
  mutation ResetPasswordByToken($input: ResetPasswordByTokenInput!) {
    resetPasswordByToken(input: $input) {
      boolean
    }
  }
`;

export const CREATE_PRESIGNED_UPLOAD_LINK = gql`
  mutation CreatePresignedUploadLink($input: CreatePresignedUploadLinkInput!) {
    createPresignedUploadLink(input: $input) {
      postURL
      getURL
      fileKey
      formData
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        fullName
        email
        companyTitle
        companyDescription
        companyAddress
        companyPhoneNumber
        companyWebsiteUrl
        logoUrl
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        id
        files {
          nodes {
            name
            externalId
            type
          }
        }
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        id
        nodeId
      }
    }
  }
`;
