import { useEffect } from 'react';
import { Banner, Spinner } from '@shopify/polaris';
import { Redirect, useParams } from 'react-router-dom';
import { Scalars, useVerifyEmailMutation } from '../../graphql';
import { Page } from '../../components';

export function VerifyEmail() {
  const { token } = useParams<{ token: Scalars['UUID'] }>();

  const [verifyEmail, { data, loading }] = useVerifyEmailMutation();

  useEffect(() => {
    if (token) {
      verifyEmail({ variables: { input: { token } } });
    }
  }, [token, verifyEmail]);

  const result = data?.verifyEmail?.boolean || false;

  return (
    <Page narrowWidth title="Email verification">
      {loading ? (
        <Spinner />
      ) : result ? (
        <Redirect to={'/login?message=email_verified'} />
      ) : (
        <Banner status="critical" title="The confirmation link is invalid." />
      )}
    </Page>
  );
}
