import IntroContent from '../../content/IntroContent.json';
import MiddleBlockContent from '../../content/MiddleBlockContent.json';
import AboutContent from '../../content/AboutContent.json';
import MissionContent from '../../content/MissionContent.json';
import ProductContent from '../../content/ProductContent.json';
import ContactContent from '../../content/ContactContent.json';
import { Container, ScrollToTop } from '../../common';
import { ContactForm, ContentBlock, MiddleBlock } from '../../components';

export function HomeComponent() {
  return (
    <Container>
      <ScrollToTop />
      <ContentBlock
        button={IntroContent.button}
        content={IntroContent.text}
        icon="ai.svg"
        id="intro"
        title={IntroContent.title}
        type="right"
      />
      <MiddleBlock
        button={MiddleBlockContent.button}
        content={MiddleBlockContent.text}
        title={MiddleBlockContent.title}
      />
      <ContentBlock
        content={AboutContent.text}
        icon="product.svg"
        id="services"
        section={AboutContent.section}
        title={AboutContent.title}
        type="left"
      />
      <ContentBlock
        content={MissionContent.text}
        icon="growth.svg"
        id="whatis"
        title={MissionContent.title}
        type="right"
      />
      <ContentBlock
        content={ProductContent.text}
        icon="data.svg"
        id="ml"
        title={ProductContent.title}
        type="left"
      />
      <ContactForm
        content={ContactContent.text}
        id="dashboard"
        title={ContactContent.title}
      />
    </Container>
  );
}
