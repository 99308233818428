import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Fade } from 'react-awesome-reveal';
import { Col, Row } from 'antd';
import { Button, SvgIcon } from '../../../common/';
import {
  ButtonWrapper,
  Content,
  ContentWrapper,
  RightBlockContainer,
} from './styles';

interface Props {
  icon: string;
  title: string;
  content: string;
  button?: any;
  id: string;
}

export function RightContentBlock({ title, content, button, icon, id }: Props) {
  const history = useHistory();
  const scrollTo = useCallback(() => {
    const element = document.getElementById('services') as HTMLDivElement;
    element.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  const loginLink = useCallback(() => history.push('/login'), [history]);

  return (
    <RightBlockContainer>
      <Fade direction="right">
        <Row align="middle" id={id} justify="space-between">
          <Col lg={11} md={11} sm={11} xs={24}>
            <ContentWrapper>
              <h6>{title}</h6>
              <Content>{content}</Content>
              <ButtonWrapper>
                {typeof button === 'object' &&
                  button.map((item: any, id: number) => {
                    const key = `${id}_${item.title}`;
                    return (
                      <Button
                        color={item.color}
                        fixedWidth
                        key={key}
                        onClick={item.type === 'login' ? loginLink : scrollTo}
                      >
                        {item.title}
                      </Button>
                    );
                  })}
              </ButtonWrapper>
            </ContentWrapper>
          </Col>
          <Col lg={11} md={11} sm={12} xs={24}>
            <SvgIcon height="100%" src={icon} width="100%" />
          </Col>
        </Row>
      </Fade>
    </RightBlockContainer>
  );
}
