import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Frame, Loading, Navigation } from '@shopify/polaris';
import {
  AppsMajor,
  HomeMajor,
  SettingsMinor,
  TeamMajor,
} from '@shopify/polaris-icons';
import { useCurrentUserQuery } from '../../graphql';
import { useToggle } from '../../hooks';
import { ContactUs, DashboardHome } from '../../screens';
import { Projects, Settings } from '..';
import { TopBar } from './TopBar';

export function Dashboard() {
  const [showNav, , , handleToggleNav] = useToggle();

  const history = useHistory();
  const { data, loading } = useCurrentUserQuery();

  useEffect(() => {
    if (!loading && !data?.currentUser?.id) {
      history.replace('/home');
    }
  }, [data, loading, history]);

  if (loading) {
    return (
      <Frame>
        <Loading />
      </Frame>
    );
  }

  const navigationMarkup = (
    <Navigation location={location.pathname}>
      <Navigation.Section fill items={items[0]} />
      <Navigation.Section items={items[1]} />
    </Navigation>
  );

  return (
    <Frame
      navigation={navigationMarkup}
      onNavigationDismiss={handleToggleNav}
      showMobileNavigation={showNav}
      topBar={<TopBar toggleNav={handleToggleNav} />}
    >
      <Switch>
        <Route component={DashboardHome} exact path="/" />
        <Route component={Projects} path="/projects" />
        <Route component={ContactUs} path="/contact-us" />
        <Route component={Settings} path="/settings" />
      </Switch>
    </Frame>
  );
}

const items = [
  [
    {
      label: 'Home',
      exactMatch: true,
      icon: HomeMajor,
      url: '/',
    },
    {
      label: 'Projects',
      icon: AppsMajor,
      url: '/projects',
    },
  ],
  [
    {
      label: 'Contact Us',
      exactMatch: true,
      icon: TeamMajor,
      url: '/contact-us',
    },

    {
      label: 'Settings',
      exactMatch: true,
      icon: SettingsMinor,
      url: '/settings',
    },
  ],
];
