import { useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Form, Formik } from 'formik';
import { TextField } from '@satel/formik-polaris';
import {
  Banner,
  Button,
  Card,
  FormLayout,
  Layout,
  Stack,
} from '@shopify/polaris';
import { useCreateForgotPasswordTokenMutation } from '../../graphql';
import { GraphqlErrors, Page } from '../../components';
import { ForgotPasswordFormValues, validationSchema } from './types';
import './ForgotPassword.css';

export function ForgotPassword() {
  const [createForgotPasswordToken, { error }] =
    useCreateForgotPasswordTokenMutation();

  const [showErrorBanner, setShowErrorBanner] = useState<
    ApolloError | undefined
  >(undefined);

  const [showSuccessBanner, setShowSuccessBanner] = useState(false);

  const handleSubmit = useCallback(
    async ({ email }: ForgotPasswordFormValues) => {
      try {
        const result = await createForgotPasswordToken({
          variables: {
            input: {
              email,
            },
          },
        });

        const tokenCreated = result.data?.createForgotPasswordToken?.boolean;

        if (tokenCreated) {
          setShowSuccessBanner(tokenCreated);
        }
      } catch (e) {
        setShowErrorBanner(e);
      }
    },
    [createForgotPasswordToken],
  );

  const handleOnDismiss = useCallback(() => setShowErrorBanner(undefined), []);

  if (showSuccessBanner) {
    return (
      <Page narrowWidth title="Forgot password">
        <Layout.Section>
          <Banner status="success" title="Your request was successful">
            <p>
              {
                'An email containing a password change link has been sent to you.'
              }
            </p>
          </Banner>
        </Layout.Section>
      </Page>
    );
  }

  return (
    <Page fullWidth title="Forgot password">
      <Layout>
        <Layout.Section oneHalf>
          {showErrorBanner ? (
            <div className="ErrorBannerContainer">
              <GraphqlErrors
                error={error}
                onDismiss={handleOnDismiss}
                title="Problem in forgot password"
              />
            </div>
          ) : null}
          <Card sectioned>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ isSubmitting, dirty }) => (
                <Form>
                  <FormLayout>
                    <TextField label="Email" name="email" />
                    <Stack alignment="center">
                      <Stack.Item fill>
                        <Button
                          disabled={!dirty}
                          loading={isSubmitting}
                          primary
                          submit
                        >
                          {'Submit'}
                        </Button>
                      </Stack.Item>
                      <Button plain url="/login">
                        {'Login'}
                      </Button>
                    </Stack>
                  </FormLayout>
                </Form>
              )}
            </Formik>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <div className="ForgotPasswordBackgroundImage" />
        </Layout.Section>
      </Layout>
    </Page>
  );
}

const initialValues: ForgotPasswordFormValues = {
  email: '',
};
