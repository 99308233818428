import { Col, Row } from 'antd';
import { Fade } from 'react-awesome-reveal';
import { SvgIcon } from '../../../common';
import {
  Content,
  ContentWrapper,
  LeftContentSection,
  MinPara,
  MinTitle,
  ServiceWrapper,
} from './styles';

interface Props {
  icon: string;
  title: string;
  content: string;
  section?: any;
  id: string;
}

export function LeftContentBlock({ icon, title, content, section, id }: Props) {
  return (
    <LeftContentSection>
      <Fade direction="left">
        <Row align="middle" id={id} justify="space-between">
          <Col lg={11} md={11} sm={12} xs={24}>
            <SvgIcon height="100%" src={icon} width="100%" />
          </Col>
          <Col lg={11} md={11} sm={11} xs={24}>
            <ContentWrapper>
              <h6>{title}</h6>
              <Content>{content}</Content>
              <ServiceWrapper>
                <Row justify="space-between">
                  {typeof section === 'object' &&
                    section.map((item: any, id: number) => {
                      const key = `${id}_${item.title}`;
                      return (
                        <Col key={key} span={11}>
                          <SvgIcon height="60px" src={item.icon} width="60px" />
                          <MinTitle>{item.title}</MinTitle>
                          <MinPara>{item.content}</MinPara>
                        </Col>
                      );
                    })}
                </Row>
              </ServiceWrapper>
            </ContentWrapper>
          </Col>
        </Row>
      </Fade>
    </LeftContentSection>
  );
}
