import { useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Form, Formik } from 'formik';
import { TextField } from '@satel/formik-polaris';
import {
  Banner,
  Button,
  Card,
  FormLayout,
  Layout,
  Stack,
} from '@shopify/polaris';
import { GraphqlErrors, Page } from '../../components';
import { useRegisterUserMutation } from '../../graphql';
import { SignupFormValues, validationSchema } from './types';
import './Signup.css';

export function Signup() {
  const [registerFinished, setRegisterfinished] = useState(false);

  const [registerUser, { error }] = useRegisterUserMutation();

  const [showErrorBanner, setShowErrorBanner] = useState<
    ApolloError | undefined
  >(undefined);

  const handleSubmit = useCallback(
    async ({
      userFullName,
      companyTitle,
      companyDescription,
      companyPhoneNumber,
      companyWebsiteUrl,
      companyAddress,
      userEmail,
      userPassword,
    }: SignupFormValues) => {
      try {
        await registerUser({
          variables: {
            input: {
              userFullName,
              companyTitle,
              companyDescription: companyDescription || null,
              companyPhoneNumber: companyPhoneNumber || null,
              companyWebsiteUrl: companyWebsiteUrl || null,
              companyAddress: companyAddress || null,
              userEmail,
              userPassword,
            },
          },
        });
        setRegisterfinished(true);
      } catch (e) {
        console.log(e);
      }
    },
    [registerUser],
  );

  const handleOnDismiss = useCallback(() => setShowErrorBanner(undefined), []);

  if (registerFinished) {
    return (
      <Page narrowWidth title="Sign up">
        <Layout.Section>
          <Banner status="success" title="Signing up completed successfully">
            <p>{'Verification link has been sent to you via email.'}</p>
          </Banner>
        </Layout.Section>
      </Page>
    );
  }

  return (
    <Page fullWidth title="Sign up">
      <Layout>
        <Layout.Section oneHalf>
          {showErrorBanner ? (
            <div className="ErrorBannerContainer">
              <GraphqlErrors
                error={error}
                onDismiss={handleOnDismiss}
                title="Problem in sign up"
              />
            </div>
          ) : null}
          <Card sectioned>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ isSubmitting, dirty }) => (
                <Form>
                  <FormLayout>
                    <FormLayout.Group>
                      <TextField
                        label="Full name"
                        name="userFullName"
                        placeholder="John Smith"
                      />
                      <TextField
                        label="Company title"
                        name="companyTitle"
                        placeholder="Royal Dutch Shell"
                      />
                    </FormLayout.Group>
                    <TextField
                      label="Company description"
                      multiline={3}
                      name="companyDescription"
                      placeholder="Oil and gas company"
                    />
                    <FormLayout.Group>
                      <TextField
                        label="Phone number"
                        name="companyPhoneNumber"
                        placeholder="0012345678"
                      />
                      <TextField
                        label="Website"
                        name="companyWebsiteUrl"
                        placeholder="https://rampco.ca"
                        type="url"
                      />
                    </FormLayout.Group>
                    <TextField
                      label="Address"
                      multiline={3}
                      name="companyAddress"
                      placeholder="Cecilia chapman 711-2880 nulla St."
                    />
                    <TextField
                      label="Email"
                      name="userEmail"
                      placeholder="rampco@example.com"
                      type="email"
                    />
                    <FormLayout.Group>
                      <TextField
                        label="Password"
                        name="userPassword"
                        placeholder="********"
                        type="password"
                      />
                      <TextField
                        label="Password confirmation"
                        name="passwordConfirmation"
                        placeholder="********"
                        type="password"
                      />
                    </FormLayout.Group>
                    <Stack alignment="center">
                      <Stack.Item fill>
                        <Button
                          disabled={!dirty}
                          loading={isSubmitting}
                          primary
                          submit
                        >
                          {'Sign up'}
                        </Button>
                      </Stack.Item>
                      <Button plain url="/login">
                        {'Login'}
                      </Button>
                    </Stack>
                  </FormLayout>
                </Form>
              )}
            </Formik>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <div className="SignupBackgroundImage" />
        </Layout.Section>
      </Layout>
    </Page>
  );
}

const initialValues: SignupFormValues = {
  userFullName: '',
  companyTitle: '',
  companyDescription: '',
  companyPhoneNumber: '',
  companyWebsiteUrl: '',
  companyAddress: '',
  userEmail: '',
  userPassword: '',
  passwordConfirmation: '',
};
