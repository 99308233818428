import React from 'react';
import { Spinner as PolarisSpinner } from '@shopify/polaris';
import './Spinner.css';

export function Spinner() {
  return (
    <div className="Spinner">
      <PolarisSpinner />
    </div>
  );
}
