import { useCallback, useState } from 'react';
import { Button, Card, FormLayout, Layout } from '@shopify/polaris';
import { Redirect, useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { TextField } from '@satel/formik-polaris';
import { ApolloError } from '@apollo/client';
import { Scalars, useResetPasswordByTokenMutation } from '../../graphql';
import { GraphqlErrors, Page } from '../../components';
import { ResetPasswordFormValues, validationSchema } from './types';

export function ResetPassword() {
  const { token } = useParams<{ token: Scalars['UUID'] }>();

  const [resetPasswordByToken, { error, data }] =
    useResetPasswordByTokenMutation();

  const [showErrorBanner, setShowErrorBanner] = useState<
    ApolloError | undefined
  >(undefined);

  const handleOnDismissErrorBanner = useCallback(
    () => setShowErrorBanner(undefined),
    [],
  );

  const handleOnSubmit = useCallback(
    async ({ password }) => {
      try {
        await resetPasswordByToken({
          variables: { input: { token, password } },
        });
      } catch (e) {
        setShowErrorBanner(e);
      }
    },
    [token, resetPasswordByToken],
  );

  const formik = useFormik<ResetPasswordFormValues>({
    initialValues,
    onSubmit: handleOnSubmit,
    validationSchema,
  });

  const { isSubmitting, dirty } = formik;

  return (
    <Page narrowWidth title="Reset password">
      <FormikProvider value={formik}>
        <Layout>
          {showErrorBanner ? (
            <Layout.Section>
              <div className="ErrorBannerContainer">
                <GraphqlErrors
                  error={error}
                  onDismiss={handleOnDismissErrorBanner}
                  title="Problem in reset password"
                />
              </div>
            </Layout.Section>
          ) : null}
          {data?.resetPasswordByToken?.boolean ? (
            <Redirect to="/login?message=password_changed" />
          ) : null}
          <Layout.Section>
            <Card sectioned>
              <Form>
                <FormLayout>
                  <TextField label="Password" name="password" type="password" />
                  <TextField
                    label="Password confirmation"
                    name="passwordConfirmation"
                    type="password"
                  />
                  <Button
                    disabled={!dirty}
                    loading={isSubmitting}
                    primary
                    submit
                  >
                    {'Submit'}
                  </Button>
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
        </Layout>
      </FormikProvider>
    </Page>
  );
}

const initialValues: ResetPasswordFormValues = {
  password: '',
  passwordConfirmation: '',
};
