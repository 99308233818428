import {
  Caption,
  Card,
  Frame,
  Icon,
  Link,
  Loading,
  Stack,
  Subheading,
  TextStyle,
} from '@shopify/polaris';
import { SettingsMajor } from '@shopify/polaris-icons';
import { useCurrentUserQuery } from '../../graphql';
import { Page } from '../../components';
import './SettingsHome.css';

export function SettingsHome() {
  const { data, loading } = useCurrentUserQuery();

  if (loading) {
    return (
      <Frame>
        <Loading />
      </Frame>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Page title="Settings">
      <Card sectioned>
        <Stack alignment="center">
          <div className="SettingsItemIcon">
            <Icon color="subdued" source={SettingsMajor} />
          </div>
          <div className="SettingsItem">
            <Link url={'/settings/profile'}>
              <Subheading>{'General'}</Subheading>
              <Caption>
                <TextStyle variation="subdued">
                  {'View and update your company details'}
                </TextStyle>
              </Caption>
            </Link>
          </div>
        </Stack>
      </Card>
    </Page>
  );
}
