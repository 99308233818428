import { Badge, ResourceItem, Stack, TextStyle } from '@shopify/polaris';
import { useMemo } from 'react';
import { Project } from '../../graphql';
import { readableDate } from '../../utils';
import './ProjectItem.css';

interface ProjectItemProps {
  project: Project;
}

export function ProjectItem({
  project: { id, nodeId, title, description, createdAt, result, errorLog },
}: ProjectItemProps) {
  const date = useMemo(() => readableDate(new Date(createdAt)), [createdAt]);

  return (
    <ResourceItem
      accessibilityLabel={`View details for ${title}`}
      id={`${nodeId}`}
      url={`projects/${id}`}
    >
      <h3>
        <TextStyle variation="strong">{title}</TextStyle>
      </h3>
      <Stack>
        <div>{`${description}`}</div>
      </Stack>
      <div className="BadgeContainer">
        <Stack spacing="tight">
          <Stack.Item fill>
            {result ? (
              <Badge progress="complete" status="success">{`DONE`}</Badge>
            ) : (
              <Badge
                progress={errorLog ? 'incomplete' : 'partiallyComplete'}
                status={errorLog ? 'critical' : 'new'}
              >
                {errorLog ? 'FAILED' : 'IN PROGRESS'}
              </Badge>
            )}
          </Stack.Item>
          <Stack.Item>
            <div>{`${date}`}</div>
          </Stack.Item>
        </Stack>
      </div>
    </ResourceItem>
  );
}
