export function setToken(token: string): void {
  localStorage.setItem('token', token);
}

export function getToken(): string | null {
  const token = localStorage.getItem('token');

  if (!token) {
    return null;
  }

  try {
    const data = JSON.parse(atob(token.split('.')[1]));
    if (data.exp < new Date().getTime() / 1000) {
      return null;
    }
  } catch {
    return null;
  }

  return token;
}
