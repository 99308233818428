import { useEffect } from 'react';
import {
  Page as PolarisPage,
  PageProps as PolarisPageProps,
} from '@shopify/polaris';

const appTitle = 'Rampco';

export function Page(props: PolarisPageProps) {
  const { title } = props;

  useEffect(() => {
    document.title = title ? `${appTitle} - ${title}` : appTitle;

    return () => {
      document.title = appTitle;
    };
  }, [title]);

  return <PolarisPage {...props} />;
}
