import * as Yup from 'yup';

export interface ProjectFormValues {
  projectTitle: string;
  projectDescription: string;
  dataSetFiles: any[];
  plotFiles: any[];
}

export const validationSchema = Yup.object({
  projectTitle: Yup.string()
    .min(2, 'Must be at least 2 characters!')
    .max(200, 'Must be less than 200 characters!')
    .required('Required field!'),
  projectDescription: Yup.string()
    .min(4, 'Must be at least 4 characters!')
    .max(300, 'Must be less than 300 characters!')
    .required('Required field!'),
});

export type ProjectFormFile = {
  name: string;
  externalId: string;
  url: string;
};
