import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as RechartsLineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface LineChartProps {
  result: any;
}

export function LineChart({ result }: LineChartProps) {
  const mean = result.target_mean_concentration;
  const best = result.target_concentration_progress;

  const data = mean.map((x: string, index: number) => {
    return {
      target_mean_concentration: x,
      target_concentration_progress: best[index],
      name: index + 1,
    };
  });

  const domainMin = Math.floor(Math.min(...[...mean, ...best]));
  const domainMax = Math.ceil(Math.max(...[...mean, ...best]));

  return (
    <RechartsLineChart
      data={data}
      height={375}
      margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
      width={550}
    >
      <Line
        dataKey="target_concentration_progress"
        stroke="#00BFA6"
        type="monotone"
      />
      <Line
        dataKey="target_mean_concentration"
        stroke="#b4445a"
        type="monotone"
      />
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <XAxis dataKey="name" />
      <YAxis domain={[domainMin, domainMax]} type="number" />
      <Tooltip />
      <Legend />
    </RechartsLineChart>
  );
}
