import { useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Layout, Toast } from '@shopify/polaris';
import { ProjectType, useCreateProjectMutation } from '../../graphql';
import { GraphqlErrors, Page } from '../../components';
import { ProjectFormValues } from './types';
import { ProjectForm } from './ProjectForm';

export function NewProject() {
  const [showRequiredAlert, setShowRequiredAlert] = useState(false);

  const history = useHistory();

  const [showErrorBanner, setShowErrorBanner] = useState<
    ApolloError | undefined
  >(undefined);

  const handleOnDimissShowRequiredAlert = useCallback(
    () => setShowRequiredAlert(!showRequiredAlert),
    [showRequiredAlert],
  );

  const [createProject, { error }] = useCreateProjectMutation();

  const handleOnSubmit = useCallback(
    async (
      {
        projectTitle,
        projectDescription,
        dataSetFiles,
        plotFiles,
      }: ProjectFormValues,
      { resetForm },
    ) => {
      const canSubmit = dataSetFiles.length > 0;

      if (!canSubmit) {
        setShowRequiredAlert(true);
        return;
      }

      const files = [
        ...dataSetFiles.map((item) => ({
          name: item.name,
          externalId: item.externalId,
          type: ProjectType.Dataset,
        })),
        ...plotFiles.map((item) => ({
          name: item.name,
          externalId: item.externalId,
          type: ProjectType.Plot,
        })),
      ];

      try {
        await createProject({
          refetchQueries: ['CurrentUser'],
          variables: {
            input: {
              title: projectTitle,
              description: projectDescription,
              files,
            },
          },
        });

        resetForm();
        history.push('/projects');
      } catch (e) {
        setShowErrorBanner(e);
      }
    },
    [createProject, history],
  );

  const handleOnDismiss = useCallback(() => setShowErrorBanner(undefined), []);

  return (
    <Page title="New Project">
      <Layout>
        <Layout.Section oneHalf>
          {showErrorBanner ? (
            <div className="ErrorBannerContainer">
              <GraphqlErrors
                error={error}
                onDismiss={handleOnDismiss}
                title="Problem in creating project"
              />
            </div>
          ) : null}
          <ProjectForm onSubmit={handleOnSubmit} />
        </Layout.Section>
      </Layout>
      {showRequiredAlert ? (
        <Toast
          content="Dataset is required field, please upload csv file Or wait till upload completely!"
          error
          onDismiss={handleOnDimissShowRequiredAlert}
        />
      ) : null}
    </Page>
  );
}
