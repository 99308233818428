import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Slide } from 'react-awesome-reveal';
import { Col, Row } from 'antd';
import { Button, SvgIcon } from '../../common';
import { Block } from '..';
import { ButtonContainer, ContactContainer } from './styles';

interface Props {
  title: string;
  content: string;
  id: string;
}

export function ContactForm({ title, content, id }: Props) {
  const history = useHistory();

  const onClickHandler = useCallback(() => history.push('/login'), [history]);

  return (
    <ContactContainer id={id}>
      <Row align="middle" justify="space-between">
        <Col lg={12} md={11} sm={24} xs={24}>
          <Slide direction="left">
            <Block content={content} title={title} />
          </Slide>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Slide direction="right">
            <Col span={24}>
              <SvgIcon height="100%" src="deal.svg" width="100%" />
            </Col>
            <ButtonContainer>
              <Button onClick={onClickHandler}>{'Login'}</Button>
            </ButtonContainer>
          </Slide>
        </Col>
      </Row>
    </ContactContainer>
  );
}
