import { useCallback, useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Avatar, Button, Caption, InlineError, Stack } from '@shopify/polaris';
import { UploadSource } from '../graphql';
import { UploadedFile } from '../types';
import { id, upload } from '../utils';

interface Props {
  uploadSource: UploadSource;
  name: string;
  accept: string;
  preview?: boolean;
  initializePreviewUrl?: string;
  previewFileName?: boolean;
}

export function UploadField({
  uploadSource,
  name,
  preview,
  accept = '*',
  initializePreviewUrl,
  previewFileName,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(
    initializePreviewUrl,
  );

  const input = useRef<HTMLInputElement | null>(null);

  const handleOpenDialog = useCallback(() => {
    input.current && input.current.click();
  }, []);

  const [field, meta] = useField(name);

  const errorText = meta.error;

  const { setFieldValue, setFieldError } = useFormikContext<{
    [x: string]: UploadedFile;
  }>();

  const handleOnChange = useCallback(() => {
    const files = input.current?.files;

    if (files?.length) {
      const file = files[0];
      setIsLoading(true);

      upload(uploadSource, file, (err, res) => {
        setIsLoading(false);

        if (err || !res) {
          setFieldError(name, 'Error in uploading');
          return;
        }

        const { externalId, url } = res;

        setPreviewUrl(url);

        setFieldValue(name, {
          name: file.name,
          externalId: externalId,
          type: file.type,
          localId: id(),
        });
      });
    }
  }, [name, setFieldError, setFieldValue, uploadSource]);

  const handleOnRemove = useCallback(() => {
    setPreviewUrl(initializePreviewUrl);
    setFieldValue(name, undefined);
  }, [initializePreviewUrl, name, setFieldValue]);

  return (
    <>
      <input
        accept={accept}
        onChange={handleOnChange}
        ref={input}
        style={inputStyle}
        type="file"
      />
      <Stack alignment="center">
        {preview ? <Avatar customer source={previewUrl} /> : null}
        {previewFileName && field.value ? (
          <Stack.Item>
            <Caption>{field.value.name}</Caption>
          </Stack.Item>
        ) : null}
        <Button loading={isLoading} onClick={handleOpenDialog}>
          {'Upload'}
        </Button>
        <Button disabled={!field.value} onClick={handleOnRemove}>
          {'Delete'}
        </Button>
      </Stack>
      {errorText ? <InlineError fieldID="" message={errorText} /> : null}
    </>
  );
}

const inputStyle = { display: 'none' };
