import { useCallback } from 'react';
import { FormLayout, Modal } from '@shopify/polaris';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { TextField } from '@satel/formik-polaris';
import { UploadSource } from '../../graphql';
import { Form, UploadField } from '../../components';
import { SettingProfileFormValues, validationSchema } from './types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: SettingProfileFormValues) => Promise<void>;
  values: SettingProfileFormValues;
  logoUrl?: string;
}

export function SettingsProfileEditModal({
  open,
  onClose,
  onSubmit,
  values: initialValues,
  logoUrl,
}: Props) {
  const formik = useFormik<SettingProfileFormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      { resetForm }: FormikHelpers<SettingProfileFormValues>,
    ) => {
      await onSubmit(values);
      resetForm({ values: { ...values } });
      onClose();
    },
  });

  const { submitForm, dirty, isSubmitting, resetForm } = formik;

  const handleOnClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  return (
    <Modal
      onClose={handleOnClose}
      open={open}
      primaryAction={{
        content: 'Save',
        onAction: submitForm,
        loading: isSubmitting,
        disabled: !dirty,
      }}
      title="Edit"
    >
      <Modal.Section>
        <FormikProvider value={formik}>
          <Form>
            <FormLayout>
              <UploadField
                accept="image/jpeg,image/png"
                initializePreviewUrl={logoUrl}
                name="logo"
                preview
                uploadSource={UploadSource.ProfileUpload}
              />
              <TextField
                label="Full name"
                name="userFullName"
                placeholder="John Smith"
              />
              <TextField
                label="Company title"
                name="companyTitle"
                placeholder="Royal Dutch Shell"
              />
              <TextField
                label="Company description"
                multiline={3}
                name="companyDescription"
                placeholder="Oil and gas company"
              />
              <TextField
                label="Phone number"
                name="companyPhoneNumber"
                placeholder="0012345678"
              />
              <TextField
                label="Website"
                name="companyWebsiteUrl"
                placeholder="https://rampco.ca"
                type="url"
              />
              <TextField
                label="Address"
                multiline={3}
                name="companyAddress"
                placeholder="Cecilia chapman 711-2880 nulla St."
              />
            </FormLayout>
          </Form>
        </FormikProvider>
      </Modal.Section>
    </Modal>
  );
}
