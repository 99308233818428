import { useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { PreviewFile } from './PreviewFile';
import { Files } from './types';

interface Props {
  name: string;
}

export function UploadedFiles({ name }: Props) {
  const [field] = useField<Files>(name);
  const { setFieldValue } = useFormikContext<{ [x: string]: string[] }>();

  const files = field.value;

  const handleOnRemove = useCallback(
    (url: string) => {
      const _files = files.filter(({ url: fileUrl }) => fileUrl !== url);
      setFieldValue(name, _files);
    },
    [files, name, setFieldValue],
  );

  const inner = files?.map(({ name, url, externalId }) => (
    <PreviewFile
      key={`${externalId}`}
      name={name}
      onRemove={handleOnRemove}
      url={url}
    />
  ));

  return <div>{inner}</div>;
}
