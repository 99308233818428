import { Container, Content, TextWrapper } from './styles';

interface Props {
  title: string;
  content: string;
}

export function Block({ title, content }: Props) {
  return (
    <Container>
      <h6>{title}</h6>
      <TextWrapper>
        <Content>{content}</Content>
      </TextWrapper>
    </Container>
  );
}
