import { Route, Switch } from 'react-router-dom';
import { Frame, Loading } from '@shopify/polaris';
import { useCurrentUserQuery } from '../graphql';
import { SettingsHome, SettingsProfile } from '../screens';

export function Settings() {
  const { data, loading } = useCurrentUserQuery();

  if (loading) {
    return (
      <Frame>
        <Loading />
      </Frame>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Switch>
      {routes.map(({ component, path, exact }) => (
        <Route component={component} exact={exact} key={path} path={path} />
      ))}
    </Switch>
  );
}

const routes = [
  {
    component: SettingsHome,
    path: '/settings',
    exact: true,
  },
  {
    component: SettingsProfile,
    path: '/settings/profile',
  },
];
