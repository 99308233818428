import { useCallback, useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Layout, Toast } from '@shopify/polaris';
import {
  ProjectFile,
  ProjectType,
  useUpdateProjectMutation,
} from '../../graphql';
import { GraphqlErrors, Page } from '../../components';
import { safeParseInt } from '../../utils';
import { ProjectFormFile, ProjectFormValues } from './types';
import { ProjectForm } from './ProjectForm';

export function EditProject() {
  const history = useHistory();
  const location = useLocation();
  const { id: _id } = useParams<{ id: string }>();

  const id = safeParseInt(_id);

  const [showRequiredAlert, setShowRequiredAlert] = useState(false);

  const [showErrorBanner, setShowErrorBanner] = useState<
    ApolloError | undefined
  >(undefined);

  const handleOnDimissShowRequiredAlert = useCallback(
    () => setShowRequiredAlert(!showRequiredAlert),
    [showRequiredAlert],
  );

  const [updateProject, { error }] = useUpdateProjectMutation();

  const handleOnSubmit = useCallback(
    async (values: ProjectFormValues, { resetForm }) => {
      const { projectTitle, projectDescription, dataSetFiles, plotFiles } =
        values;

      const canSubmit = dataSetFiles.length > 0;

      if (!canSubmit) {
        setShowRequiredAlert(true);
        return;
      }

      const files = [
        ...dataSetFiles.map((item) => ({
          name: item.name,
          externalId: item.externalId,
          type: ProjectType.Dataset,
        })),
        ...plotFiles.map((item) => ({
          name: item.name,
          externalId: item.externalId,
          type: ProjectType.Plot,
        })),
      ];

      try {
        await updateProject({
          variables: {
            input: {
              id,
              patch: {
                title: projectTitle,
                description: projectDescription,
                files,
              },
            },
          },
        });

        resetForm({ values: { ...values } });
        history.replace('/projects');
      } catch (e) {
        setShowErrorBanner(e);
      }
    },
    [id, history, updateProject],
  );

  const handleOnDismiss = useCallback(() => setShowErrorBanner(undefined), []);

  const initVals = useMemo(() => {
    if (!location.state) {
      return undefined;
    }

    const { project, files }: any = location.state;

    if (!project) {
      return undefined;
    }

    const { title, description } = project;

    const dataSetFiles = files.find(
      (item: ProjectFile) => item.type === ProjectType.Dataset,
    );
    const plotFiles = files.find(
      (item: ProjectFile) => item.type === ProjectType.Plot,
    );

    const vals: ProjectFormValues = {
      projectTitle: title,
      projectDescription: description || '',
      dataSetFiles: dataSetFiles
        ? [dataSetFiles].map(({ name, externalId, url }: ProjectFormFile) => ({
            name,
            externalId,
            url,
          }))
        : [],
      plotFiles: plotFiles
        ? [plotFiles].map(({ name, externalId, url }: ProjectFormFile) => ({
            name,
            externalId,
            url,
          }))
        : [],
    };

    return vals;
  }, [location.state]);

  return (
    <Page title="Edit Project">
      <Layout>
        <Layout.Section oneHalf>
          {showErrorBanner ? (
            <div className="ErrorBannerContainer">
              <GraphqlErrors
                error={error}
                onDismiss={handleOnDismiss}
                title="Problem in editing project"
              />
            </div>
          ) : null}
          <ProjectForm
            discardAction={`/projects/${id}`}
            onSubmit={handleOnSubmit}
            values={initVals}
          />
        </Layout.Section>
      </Layout>
      {showRequiredAlert ? (
        <Toast
          content="Dataset is required field, please upload csv file Or wait till upload completely!"
          error
          onDismiss={handleOnDimissShowRequiredAlert}
        />
      ) : null}
    </Page>
  );
}
