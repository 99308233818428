import { useCallback } from 'react';
import { Badge, Caption, Card, Heading, Layout } from '@shopify/polaris';
import { EditMajor } from '@shopify/polaris-icons';
import { useHistory, useParams } from 'react-router-dom';
import {
  ProjectType,
  useProjectFilesQuery,
  useProjectQuery,
} from '../../graphql';
import { safeParseInt } from '../../utils';
import { DataTable, LineChart, Page, Spinner } from '../../components';
import { Progress, Status } from './types';

export function ProjectDetails() {
  const history = useHistory();
  const { id: _id } = useParams<{ id: string }>();

  const id = safeParseInt(_id);

  const { data, loading } = useProjectQuery({
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  });

  const { data: filesData, loading: filesLoading } = useProjectFilesQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        projectId: {
          equalTo: id,
        },
        deletedAt: {
          isNull: true,
        },
      },
    },
  });

  const project = data?.project_;
  const files = filesData?.projectFiles?.nodes;

  const plot = files?.find((item) => item.type === ProjectType.Plot);

  const handleEditOnAction = useCallback(
    () =>
      history.push({
        pathname: `/projects/edit/${id}`,
        state: { project, files },
      }),
    [files, history, id, project],
  );

  if (loading || filesLoading) {
    return <Spinner />;
  }

  let projectStatus: {
    title: string;
    progress: Progress;
    status?: Status;
    caption?: string;
  } = {
    title: 'IN PROGRESS',
    progress: 'partiallyComplete',
    caption: 'Project is in progress!',
  };

  if (!project?.result && project?.errorLog) {
    projectStatus = {
      title: 'FAILED',
      progress: 'incomplete',
      status: 'critical',
      caption: `Project error log: ${project.errorLog}`,
    };
  } else if (project?.result) {
    projectStatus = {
      title: 'DONE',
      progress: 'complete',
      status: 'success',
      caption: undefined,
    };
  }

  return (
    <Page
      secondaryActions={[
        { content: 'Edit', onAction: handleEditOnAction, icon: EditMajor },
      ]}
      title="Project details"
      titleMetadata={
        <Badge progress={projectStatus.progress} status={projectStatus.status}>
          {projectStatus.title}
        </Badge>
      }
    >
      <Layout>
        <Layout.AnnotatedSection title="Information">
          <Card sectioned>
            <Heading>{project?.title}</Heading>
            <Caption>{project?.description}</Caption>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Chart">
          <Card sectioned>
            {!project?.result ? (
              `${projectStatus.caption}`
            ) : (
              <LineChart result={project.result} />
            )}
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Fitted Parameter Values">
          <Card sectioned>
            {!project?.result ? (
              `${projectStatus.caption}`
            ) : (
              <DataTable result={project.result} />
            )}
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Plot">
          <Card sectioned>
            {plot ? (
              <img
                alt="plot"
                height="100%"
                src={plot?.url || ''}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                width="100%"
              />
            ) : (
              'Not exists!'
            )}
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}
