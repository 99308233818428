import React from 'react';
import { Button, Stack, Thumbnail } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';

interface Props {
  url: string | null;
  onRemove: (url: string) => void;
  name: string;
}

export function PreviewFile({ url, onRemove, name }: Props) {
  const handleOnRemove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onRemove(url as string);
  };

  return (
    <div className="Item">
      <Stack alignment="center">
        <a href={url as string} rel="noopener noreferrer" target="_blank">
          <Thumbnail
            alt={name}
            size="small"
            source={
              url ||
              'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
            }
          />
        </a>
        <p>{name}</p>
        {/* eslint-disable-next-line */}
        <div onClick={handleOnRemove}>
          <Button destructive icon={DeleteMinor} />
        </div>
      </Stack>
    </div>
  );
}
