import React from 'react';
import { StyledContainer } from './styles';

interface Props {
  border?: boolean;
  children: React.ReactNode;
}

export function Container({ border, children }: Props) {
  return <StyledContainer border={border}>{children}</StyledContainer>;
}
