interface Props {
  src: string;
  width: string;
  height: string;
}

export function SvgIcon({ src, width, height }: Props) {
  return (
    <img alt={src} height={height} src={`/img/svg/${src}`} width={width} />
  );
}
