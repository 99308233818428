import {
  ColumnContentType,
  Heading,
  DataTable as ShopifyDataTable,
} from '@shopify/polaris';
import { useCallback } from 'react';

interface Props {
  result: any;
}

export function DataTable({ result }: Props) {
  const getKey = useCallback((str, idx) => encodeURI(`${str},${idx}`), []);

  const fittedData = result.fitted_parameters_values;
  const headings = ['Data', 'Value'];
  const keys = Object.keys(fittedData);
  const rows = keys.map((key, id) => [
    <Heading key={getKey(key, id)}>{key}</Heading>,
    <p key={getKey(key, id * 10)}>{fittedData[key]}</p>,
  ]);
  const columnContentTypes = new Array(headings.length).fill('text');

  return (
    <ShopifyDataTable
      columnContentTypes={columnContentTypes as ColumnContentType[]}
      headings={headings}
      rows={rows}
    />
  );
}
