import axios from 'axios';
import { UploadSource, client } from '../graphql';
import { CREATE_PRESIGNED_UPLOAD_LINK } from '../graphql/mutations';

export function upload(
  uploadSource: UploadSource,
  file: File,
  cb: (
    err: Error | undefined,
    res?: { externalId: string; url: string } | undefined,
  ) => void,
  onProgress?: (p: number) => void,
) {
  const cancelSource = axios.CancelToken.source();

  client
    .mutate({
      mutation: CREATE_PRESIGNED_UPLOAD_LINK,
      variables: { input: { mimeType: file.type, uploadSource } },
    })
    .then(({ data: _data }) => {
      const postData = _data.createPresignedUploadLink;

      if (!postData) {
        throw new Error('presigned link create failed');
      }

      const data = new FormData();

      const { formData, postURL, fileKey, getURL } = postData;

      for (const k in formData) {
        data.append(k, formData[k]);
      }

      data.append('file', file);

      axios
        .request({
          method: 'POST',
          url: postURL,
          data,
          cancelToken: cancelSource.token,
          onUploadProgress: onProgress
            ? (p: ProgressEvent) => {
                onProgress(p.loaded / p.total - 0.2);
              }
            : undefined,
        })
        .then(() => {
          cb(undefined, {
            externalId: fileKey,
            url: getURL,
          });
          onProgress ? onProgress(1) : undefined;
        })
        .catch((error) => {
          cb(error);
        });
    })
    .catch((error) => {
      cb(error);
    });

  return cancelSource.cancel;
}
