/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Form, Formik } from 'formik';
import { TextField } from '@satel/formik-polaris';
import {
  Banner,
  Button,
  Card,
  FormLayout,
  Layout,
  Stack,
} from '@shopify/polaris';
import { StringParam, useQueryParams } from 'use-query-params';
import { setToken, useLoginUserMutation } from '../../graphql';
import { GraphqlErrors, Page } from '../../components';
import { LoginFormValues, validationSchema } from './types';
import './Login.css';

export function Login() {
  const [loginUser, { error }] = useLoginUserMutation();

  const [showErrorBanner, setShowErrorBanner] = useState<
    ApolloError | undefined
  >(undefined);

  const [{ message }] = useQueryParams({
    message: StringParam,
  });

  const [showMessageBanner, setShowMessageBanner] = useState(!!message);

  const handleSubmit = useCallback(
    async ({ email, password }: LoginFormValues) => {
      try {
        const result = await loginUser({
          variables: {
            input: {
              email,
              password,
            },
          },
        });

        const token = result.data?.loginUser?.jwtToken;

        setToken(token);
        window.location.pathname = '/';
      } catch (e) {
        setShowErrorBanner(e);
      }
    },
    [loginUser],
  );

  const handleOnDismissErrorBanner = useCallback(
    () => setShowErrorBanner(undefined),
    [],
  );

  const handleOnDismissMessageBanner = useCallback(
    () => setShowMessageBanner(false),
    [],
  );

  return (
    <Page fullWidth title="Login">
      <Layout>
        <Layout.Section oneHalf>
          {showErrorBanner ? (
            <div className="ErrorBannerContainer">
              <GraphqlErrors
                error={error}
                onDismiss={handleOnDismissErrorBanner}
                title="Problem in login"
              />
            </div>
          ) : null}
          {showMessageBanner && message! in messages ? (
            <div className="ErrorBannerContainer">
              <Banner onDismiss={handleOnDismissMessageBanner} status="success">
                <p>{messages[message as keyof typeof messages]}</p>
              </Banner>
            </div>
          ) : null}
          <Card sectioned>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ isSubmitting, dirty }) => (
                <Form>
                  <FormLayout>
                    <TextField label="Email" name="email" />
                    <TextField
                      label="Password"
                      name="password"
                      type="password"
                    />
                    <Stack alignment="center">
                      <Stack.Item fill>
                        <Button
                          disabled={!dirty}
                          loading={isSubmitting}
                          primary
                          submit
                        >
                          {'Login'}
                        </Button>
                      </Stack.Item>
                      <Button plain url="/signup">
                        {'Sign up'}
                      </Button>
                      <Button plain url="/forgot-password">
                        {'Forgot password?'}
                      </Button>
                    </Stack>
                  </FormLayout>
                </Form>
              )}
            </Formik>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <div className="LoginBackgroundImage" />
        </Layout.Section>
      </Layout>
    </Page>
  );
}

const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

const messages = {
  email_verified: 'Your email has been verified.',
  password_changed: 'Your password has been successfully changed.',
};
