import { LeftContentBlock } from './LeftContentBlock';
import { RightContentBlock } from './RightContentBlock';

export interface Props {
  icon: string;
  title: string;
  content: string;
  section?: any;
  button?: any;
  t?: any;
  id: string;
  type?: string;
}

export function ContentBlock(props: Props) {
  const { type } = props;
  if (type === 'left') return <LeftContentBlock {...props} />;
  if (type === 'right') return <RightContentBlock {...props} />;
  return null;
}
