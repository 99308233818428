import { useCallback } from 'react';
import { Col, Row } from 'antd';
import { Slide } from 'react-awesome-reveal';
import { Button } from '../../common';
import { Content, ContentWrapper, MiddleBlockSection } from './styles';

interface Props {
  title: string;
  content: string;
  button: string;
}

export function MiddleBlock({ title, content, button }: Props) {
  const scrollTo = useCallback(() => {
    const element = document.getElementById('whatis') as HTMLDivElement;
    element.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  return (
    <MiddleBlockSection>
      <Slide direction="up">
        <Row align="middle" justify="center">
          <ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <h6>{title}</h6>
              <Content>{content}</Content>
              {button && <Button onClick={scrollTo}>{button}</Button>}
            </Col>
          </ContentWrapper>
        </Row>
      </Slide>
    </MiddleBlockSection>
  );
}
