import { SvgIcon } from '../../common';

interface Props {
  href: string;
  src: string;
}
export function SocialLink({ href, src }: Props) {
  return (
    <a
      aria-label={src}
      href={href}
      key={src}
      rel="noopener noreferrer"
      target="_blank"
    >
      <SvgIcon height="25px" src={src} width="25px" />
    </a>
  );
}
