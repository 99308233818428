import { useCallback, useRef } from 'react';
import { Card, Filters, Pagination, ResourceList } from '@shopify/polaris';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useDebounce } from 'use-debounce';
import { Project, ProjectsOrderBy, useProjectsQuery } from '../../graphql';
import { searchTextCleanup } from '../../utils';
import { Page } from '../../components';
import { ProjectItem } from './ProjectItem';
import './ProjectsHome.css';

export function ProjectsHome() {
  const [pathState, setPathState] = useQueryParams({
    s: withDefault(StringParam, ''),
    p: withDefault(NumberParam, 1),
    o: withDefault(StringParam, ProjectsOrderBy.IdDesc),
  });

  const setPathStateRef = useRef(setPathState);
  setPathStateRef.current = setPathState;
  const { s: pathSearch, p: page, o: orderBy } = pathState;

  const handleSearch = useCallback(
    (newVal) => setPathStateRef.current({ s: newVal, p: undefined }, 'pushIn'),
    [],
  );

  const handleClearAll = useCallback(
    () =>
      setPathStateRef.current(
        {
          s: undefined,
          p: undefined,
        },
        'push',
      ),
    [],
  );

  const handleUnsetFilter = useCallback(
    (key) => setPathState({ [key]: undefined, p: undefined }, 'pushIn'),
    [setPathState],
  );

  const handleClearQuery = useCallback(
    () => handleUnsetFilter('s'),
    [handleUnsetFilter],
  );

  const nextPage = useCallback(
    () => setPathStateRef.current({ p: page + 1 }, 'pushIn'),
    [page],
  );
  const prevPage = useCallback(() => {
    const p = page - 1;
    setPathStateRef.current({ p: p > 1 ? p : undefined }, 'pushIn');
  }, [page]);

  const handleSetOrderBy = useCallback(
    (newVal) => setPathStateRef.current({ p: undefined, o: newVal }, 'pushIn'),
    [],
  );

  const [debouncedValue] = useDebounce(pathSearch, 350);

  const { data, loading } = useProjectsQuery({
    fetchPolicy: 'network-only',
    variables: {
      first: 5,
      offset: (page - 1) * 5,
      orderBy: [orderBy as ProjectsOrderBy],
      filter: {
        title: {
          likeInsensitive: searchTextCleanup(debouncedValue),
        },
      },
    },
  });

  const projects = data?.projects;

  const filterControl = (
    <Filters
      filters={[]}
      onClearAll={handleClearAll}
      onQueryChange={handleSearch}
      onQueryClear={handleClearQuery}
      queryValue={pathSearch}
    />
  );

  const renderItem = useCallback((project: Project) => {
    return <ProjectItem project={project} />;
  }, []);

  const pageInfo = projects?.pageInfo || fakePageInfo;

  return (
    <Page
      primaryAction={{
        content: 'Create Project',
        url: '/projects/new',
      }}
      title="Projects"
    >
      <Card>
        <ResourceList
          filterControl={filterControl}
          items={projects?.nodes || []}
          loading={loading}
          onSortChange={handleSetOrderBy}
          renderItem={renderItem}
          resourceName={resourceName}
          sortOptions={sortOptions}
          sortValue={orderBy}
          totalItemsCount={projects?.totalCount}
        />
        <div className="ProjectListFooter">
          <Pagination
            hasNext={pageInfo.hasNextPage}
            hasPrevious={pageInfo.hasPreviousPage}
            onNext={nextPage}
            onPrevious={prevPage}
          />
        </div>
      </Card>
    </Page>
  );
}

const resourceName = {
  singular: 'project',
  plural: 'projects',
};

const fakePageInfo = {
  hasNextPage: false,
  hasPreviousPage: false,
};

const sortOptions = [
  { label: 'Newest', value: ProjectsOrderBy.IdDesc },
  { label: 'Oldest', value: ProjectsOrderBy.IdAsc },
];
