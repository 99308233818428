import {
  Caption,
  Card,
  Heading,
  Icon,
  Layout,
  Stack,
  TextContainer,
} from '@shopify/polaris';
import { EmailMajor, LocationMajor, PhoneMajor } from '@shopify/polaris-icons';
import { Page } from '../../components';
import './styles.css';

export function ContactUs() {
  return (
    <Page title="Contact Us">
      <Layout>
        <Layout.AnnotatedSection title="Information">
          <Card>
            <Card.Section>
              <TextContainer>
                <Stack>
                  <Icon color="primary" source={LocationMajor} />
                  <Heading>{'Address'}</Heading>
                </Stack>
                <Caption>
                  {
                    'Spark Centre Head Office, Suite 300, 2 Simcoe Street South, Oshawa, L1H 8C1, Canada'
                  }
                </Caption>
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <TextContainer>
                <Stack>
                  <Icon color="primary" source={PhoneMajor} />
                  <Heading>{'Phone'}</Heading>
                </Stack>
                <Caption>
                  <a className="Email-Link" href="tel:0016474929110">
                    {'+1 (647) 492-9110'}
                  </a>
                </Caption>
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <TextContainer>
                <Stack>
                  <Icon color="primary" source={EmailMajor} />
                  <Heading>{'Email'}</Heading>
                </Stack>
                <Caption>
                  <a className="Email-Link" href="mailto:contact@rampco.ca">
                    {'contact@rampco.ca'}
                  </a>
                </Caption>
              </TextContainer>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}
